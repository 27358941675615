export enum EButtonSize {
    M = 'm',
    S = 's',
    XS = 'xs',
}

export enum EButtonStyle {
    FILL = 'fill',
    TEXT = 'text',
}

export enum EButtonColor {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    SECONDARY_ADDITIONAL = 'secondary-additional',
    SUBTLE = 'subtle',
}

export enum EButtonType {
    SUBMIT = 'submit',
    BUTTON = 'button',
    RESET = 'reset',
}
