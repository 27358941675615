export enum EDataTestIdKeys {
    BUTTON,
    RESET_BUTTON,
    BUTTON_TOGGLE,
    AUTOCOMPLETE,
    CHECKBOX,
    DATE,
    INPUT,
    SELECT,
    OPTION,
    TEXTAREA,
    SWITCH,
}

const defaultKeys = {
    [EDataTestIdKeys.BUTTON]: 'button',
    [EDataTestIdKeys.RESET_BUTTON]: 'reset_button',
    [EDataTestIdKeys.BUTTON_TOGGLE]: 'button_toggle',
    [EDataTestIdKeys.AUTOCOMPLETE]: 'autocomplete_control',
    [EDataTestIdKeys.CHECKBOX]: 'checkbox_control',
    [EDataTestIdKeys.DATE]: 'date_control',
    [EDataTestIdKeys.INPUT]: 'input_control',
    [EDataTestIdKeys.SELECT]: 'select_control',
    [EDataTestIdKeys.OPTION]: 'option',
    [EDataTestIdKeys.TEXTAREA]: 'textarea_control',
    [EDataTestIdKeys.SWITCH]: 'switch_control',
};

export const getDataTestId = (key: EDataTestIdKeys, data: string): string => {
    const transformData = data
        ? data
              .replace(/[^\w\s]/g, '')
              .replace(/^\s+|\s+$/g, '')
              .replace(/\s+/g, ' ')
        : '';

    const dataTestId = defaultKeys[key] + (transformData ? `_${transformData}` : '');

    return dataTestId.toLocaleLowerCase().replace(/\s/g, '_');
};
