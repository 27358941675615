import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    HostBinding,
    Input,
    OnDestroy,
    OnInit,
    ViewChild
} from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { TInputMode } from '@shared/components/form-controls/interfaces/input-mode.type';
import { EDataTestIdKeys, getDataTestId } from '@shared/utils';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutocompleteAttrEnum, IControlBadge, InputMaskEnum } from '../../interfaces';
import { InputModeValueEnum } from '@shared/enums';

@Component({
    selector: 'mv-input-form-control',
    templateUrl: './input-form-control.component.html',
    styleUrls: ['./input-form-control.component.scss'],
    host: { class: 'mv-control' },
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InputFormControlComponent implements OnInit, OnDestroy {
    @Input() label: string = '';
    @Input() testId: string = '';
    @Input() mask: InputMaskEnum | null = null;
    @Input() inputMode: TInputMode | InputModeValueEnum = 'text';
    @Input() thousandSeparator: string = '';
    @Input() separatorLimit: string = '';
    @Input() decimalMarker: '.' | ',' | ['.', ','] = '.';
    @Input() control: FormControl = new FormControl('');
    @Input() disabled: boolean = false;
    @Input() prefix?: string;
    @Input() type?: string;
    @Input() autocompleteAttrName = AutocompleteAttrEnum.Off;
    @Input() placeholder: string = '';
    @Input() showErrorMessage: boolean = true;

    @Input() badge: IControlBadge | null;

    @ViewChild('input') input!: ElementRef;

    @HostBinding('class.mv-control-invalid') get errorState(): boolean {
        return this.control.touched && this.control.invalid;
    }

    public isFocus: boolean = false;

    // Data Test ID
    public inputDataTestId: string;

    private destroy$ = new Subject<void>();

    public get isDisabled(): boolean {
        return this.disabled || this.control.disabled;
    }

    public get isLabelFloating(): boolean {
        return this.isFocus || !!this.prefix || !!this.control.value;
    }

    public get isSpecialSymbolShouldBeDropped(): boolean {
        return this.mask
            && this.mask !== InputMaskEnum.ZIP_CODE
            && this.mask !== InputMaskEnum.MEDICATION_QUANTITY
            && this.mask !== InputMaskEnum.INT_FLOAT_NUMBERS
            && this.mask !== InputMaskEnum.BLOOD_PRESSURE
            && this.mask !== InputMaskEnum.SOCIAL_SECURITY_NUMBER;
    }

    public get isRequired(): boolean {
        return this.control.hasValidator(Validators.required);
    }

    public get isError(): boolean {
        return this.control.invalid && this.control.touched;
    }

    constructor(private cdr: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.setDataTestId();
        this.subscribeValueAndStatusChanges();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public onFocus(): void {
        this.input?.nativeElement?.focus();
        this.isFocus = true;
    }

    public onBlur(): void {
        this.input?.nativeElement?.blur();
        this.isFocus = false;
    }

    private setDataTestId(): void {
        this.inputDataTestId = getDataTestId(EDataTestIdKeys.INPUT, this.testId || this.label);
    }

    private subscribeValueAndStatusChanges(): void {
        merge(this.control.valueChanges, this.control.statusChanges)
            .pipe(takeUntil(this.destroy$))
            .subscribe(() => this.cdr.markForCheck());
    }
}
